:root {
  --progress-bar-border-width: 2px;
  --progress-bar-height: 20px;
  --progress-bar-border-radius: 20px;
  --progress-bar-border-color: #51c3f2;
  --progress-bar-background-color: #e1f5fd;
  --progress-bar-value-background-color: #08c;
}

.progress {
  width: 100%;
  height: var(--progress-bar-height);
  overflow: hidden;
  background-color: var(--progress-bar-background-color);
  border: var(--progress-bar-border-width) solid var(--progress-bar-border-color);
  border-radius: var(--progress-bar-border-radius);
}

.value {
  display: block;
  height: 100%;
  background-color: var(--progress-bar-value-background-color);

  &.withBorderRight {
    border-right: var(--progress-bar-border-width) solid var(--progress-bar-value-border-color);
  }
}
