.title {
	display: flex;
	flex-direction: column;
	color: #fff;
	font-style: normal;
	padding-top: 30px;
}

.number {
	-webkit-text-stroke: 12px rgba(255, 255, 255, 0.5);
	font-weight: 800;
	font-size: 74px;
	line-height: 74px;
}

@media only screen and (min-width: 1024px) and (max-width: 1137px) {
	.number {
		font-size: 64px;
		line-height: 64px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
	.title {
		padding-top: 22px;
	}

	.number {
		font-size: 44px;
		line-height: 44px;
	}
}

.textContainer {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	height: 50px;
}

.buttonContainer {
	position: absolute;
	right: 20px;
	display: flex;
	align-items: center;
	pointer-events: all;

	div:not(:last-child) {
		margin-right: 15px;
	}
}

.infoContainer {
	outline: 0;
}
