.optionBox {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.vertical {
	.option {
		@media (max-height: 768px) {
			max-height: 297px;
			place-self: center;
		}
		flex-direction: column;
		min-height: 168px;
		height: 100%;
	}

	.audioBtn {
		height: 74px;
		margin-bottom: 20px;
		width: 100%;
	}

	.action {
		padding: 20px 20px 0;
		width: 100%;
	}

	.imgContainer {
		@media (max-height: 768px) {
			max-width: 100%;
			width: auto;
			height: 100%;
		}
		@media (max-height: 600px) {
			max-height: 150px;
		}
		align-items: flex-end;
		justify-content: center;
	}
}

.horizontal {
	.option {
		flex-direction: row;
	}

	.audioBtn {
		margin-right: 20px;
	}

	.action {
		padding: 20px;
		width: initial;
	}

	.textContainer {
		text-align: left;
	}

	.imgContainer {
		align-items: center;
		justify-content: flex-start;
		@media (max-height: 800px) {
			max-height: unset;
			padding: 4px;
			max-width: unset;
			width: auto;
			height: 100%;
		}
	}
}

.option {
	cursor: pointer;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-style: normal;
	font-weight: 500;
	font-size: 32px;
	line-height: 32px;
	color: #FFFFFF;
	border: 3px solid #51C3F2;
	border-radius: 12px;
	padding: 3px;
	width: 100%;

	.action {
		display: flex;

		> svg {
			margin: auto;
		}
	}

	@media (any-hover: hover) {
		&:hover, &:active {
			background: #0088CC;

			.checkBox {
				path {
					fill: #51C3F2;
				}
			}

			svg {
				circle {
					stroke: #51C3F2;
				}
			}
		}

	}
}

.imgContainer {
	height: inherit;
	padding: 15px;
	flex-grow: 1;
	display: flex;
	width: 100%;
	overflow: auto;

	img {
		max-height: 100%;
		max-width: 100%;
		width: auto;
		height: 100%;
		object-fit: contain;
	}

	@media (max-height: 600px) {
		padding: 5px;

		img {
			height: auto;
		}
	}
}

.disabled {
	opacity: 0.6;
	pointer-events: none;
	cursor: default;
}

.selected {
	background: #0077B8;
	border-width: 6px;
	padding: 0;

	svg {
		fill: #FFFFFF;

		circle {
			stroke: #51C3F2;
		}
	}
}




.textContainer {
	height: 100%;
	display: flex;
	align-items: center;
}
