.template {
    display: flex;
    width: 100%;
	height: 100%;
}

.column {
	margin: auto;
    width: 50%;
}

.left {
	display: flex;
	flex-direction: column;
	justify-content: center;
	justify-items: center;
	align-items: center;
}

.right {
	display: flex;
	flex-flow: column;
	justify-content: center;
}

.optionBox {
	flex: initial;
	min-height: 110px;
	flex-direction: row;
}

.imgContainer {
	padding: 15px;
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	overflow: auto;
	img {
		max-height: 100%;
		max-width: 100%;
		width: auto;
		height: auto;
	}
}

.optionBox ~ .optionBox {
	margin-top: 20px;
}

.text {
	padding: 15px;
	color: #fff;
	font-size: 32px;
	font-style: normal;
	font-weight: 400;
	line-height: 54px;
	text-align: left;
}

.textContainer {
	display: flex;
}

.customizeOption {
	@media (max-height: 830px) {
		line-height: initial;
	}

	@media (max-height: 630px) {
		font-size: 27px;
	}
}

.customizeAction {
	@media (max-height: 780px) {
		> svg {
			width: 40px;
			height: 40px;
		}
	}
}
