.cardBody {
	padding-top: 72px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

@media only screen and (min-width: 1024px) and (max-width: 1137px) {
	.cardBody {
		padding-top: 46px;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
	.cardBody {
		padding-top: 10px;

		> svg {
			width: 115px;
		}
	}
}
