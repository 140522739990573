.template {
	display: flex;
	width: 100%;
	height: 100%;
}

.column {
	height: 100%;
	margin: auto;
	width: 50%;
}

.left {
	display: flex;
	flex-direction: column;
	justify-content: center;
	justify-items: center;
	align-items: center;
}

.reverse {
	flex-direction: column-reverse;
}

.right {
	display: flex;
	flex-flow: column;
	justify-content: center;
}

.optionBox {
	flex: initial;
	flex-direction: row;
	min-height: 110px;

	@media (max-height: 600px) {
		min-height: auto;
	}
}

.optionBox4Items {
	@media (max-height: 768px) {
		max-height: 84px;
		min-height: unset;
	}
}

.imgContainer {
	max-height: 100%;
	padding: 15px;
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;

	img {
		max-height: 100%;
		max-width: 100%;
		width: auto;
		height: auto;

		@media (max-height: 600px) {
			max-height: 250px;
		}
	}
}

.optionBox ~ .optionBox {
	margin-top: 20px;
}

.text {
	padding: 15px;
	color: #fff;
	font-size: 32px;
	font-style: normal;
	font-weight: 400;
	line-height: 36px;
	text-align: left;
}

.textContainerIpad {
	min-height: 90px;
}

.textContainer {
	max-height: 100%;
	display: flex;
	align-items: center;
}

.customizeOption {
	@media (max-height: 830px) {
		line-height: initial;
	}

	@media (max-height: 630px) {
		font-size: 27px;
	}
}

.customizeAction {
	@media (max-height: 780px) {
		> svg {
			width: 40px;
			height: 40px;
		}
	}
}

.bigAudioBtn {
	svg {
		width: 75px;
		height: 75px;
	}
}

.audioBtnIpad {
	min-height: 90px;
	align-items: center;
}

.topAudioBtn {
	padding-top: 8px;
	padding-bottom: 8px;
	align-self: flex-start;
	height: fit-content;
	display: flex;
}

.alignSelfCenter {
	align-self: center;
}

.noBottomP {
	padding-bottom: 0px;
}

.noTopP {
	padding-top: 0px;
}
