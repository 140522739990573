/* You can add global styles to this file, and also import other style files */

html {
	height: 100%;
}

body {
	height: 100%;
	overflow: hidden;
	margin: 0;
	padding: 0;
	min-width: 320px;
}

#root {
	width: 100%;
	height: 100%;
}
