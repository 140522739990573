.closeButton {
	padding: 0;
	cursor: pointer;
	box-shadow: none;
	border: 2px solid #828282;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover,
	&:active {
		border-color: #52aedc;

		svg {
			path {
				fill: #52aedc;
			}
		}
	}
}
