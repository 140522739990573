.modal {
	border: 6px solid #fff;
	width: 100%;
	max-width: 527px;
	height: 100%;
	max-height: 425px;
}

.modalBody {
	background: #0088CC;
	padding: 0;
	display: grid;
	grid-gap: 20px;
}

.bookIcon {
	display: block;
	margin: auto;
}

.audioButton {
	display: block;
	margin: auto;
}

.closeIcon {
	position: absolute;
	top: 25px;
	right: 32px;
	z-index: 1;
	svg path {
		stroke: #fff;
	}
}

.modalContainer {
	&>div:first-child {
		background-color: rgba(0, 0, 0, 0.87);
	}
}
