:root {
	--ac-card-border-color: #51c3f2;
	--ac-card-border-radius: 12px;
	--ac-card-container-padding-left: 25px;
	--ac-card-container-padding-right: 16px;
	--ac-button-play-size: 50px;
}

.cardContainer {
	border: 3px solid var(--ac-card-border-color);
	border-radius: var(--ac-card-border-radius);

	&:not(:last-child) {
		margin-bottom: 20px;
	}
}

.assignmentTitleInfo {
	display: flex;
	width: 100%;
	justify-content: space-between;
	background: var(--ac-card-border-color);
	padding: 5px var(--ac-card-container-padding-right) 5px var(--ac-card-container-padding-left);
}

.cardContent {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 6px var(--ac-card-container-padding-right) 14px var(--ac-card-container-padding-left);
	letter-spacing: 0.25px;
	background: #fff;
	border-bottom-right-radius: var(--ac-card-border-radius);
	border-bottom-left-radius: var(--ac-card-border-radius);

	h3 {
		letter-spacing: 0.5px;
		padding: 8px;
		padding-left: 0;
	}
}

.testInfo {
	display: flex;
	align-items: center;
	margin-left: auto;
}

.testProgress {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-left: 20px;
	margin-right: 20px;
}

.progressBarInfo {
	display: flex;
	align-items: center;
	margin-left: 20px;

	.progressBar {
		width: 155px;
		margin-right: 10px;
	}
}

.buttonPlay {
	position: relative;
	width: var(--ac-button-play-size);
	height: var(--ac-button-play-size);
	flex-shrink: 0;

	svg {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 32%;
		margin: auto;
		scale: 0.7;
	}
}
