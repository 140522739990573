.template {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	justify-content: center;
}

.optionsContainer {
	display: grid;
	justify-items: center;
	align-items: center;
	width: 100%;
	gap: 20px;
	grid-template-rows: 100%;
	@media (max-height: 600px) {
		padding: 15px;
	}
}

.option {
	justify-content: center;
	max-height: 433px;
	width: 100%;
	height: 100%;
}
