.audioButton {
    @media (max-height: 800px) {
        min-height: 90px;
    }
    border: none;
    background: none;
    transition: 0.3s;
    >svg {
        overflow: visible;
    }

	@media (max-height: 600px) {
		min-height: auto;

		> svg {
			width: 45px;
			height: 45px;
		}
	}
}

.playing {
    transform: scale(1.25);
    svg {
        circle {
            stroke: #FFF;
            stroke-opacity: 1;
        }
    }
}

@media (hover: hover) {
    .audioButton:hover {
        transform: scale(1.25);
    }
}
