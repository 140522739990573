:root {
  /* Text: Color Primary */
  --text-color-primary: #0077b8;

  /* Text: Color Secondary */
  --text-color-secondary: #666;

  /* Text: Color Black */
  --text-color-black: #000;

  /* Text: Color White */
  --text-color-white: #fff;

  /* Text: Current Color */
  --text-color-current: currentcolor;
}

.text {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
}

@colors: {
  primary: var(--text-color-primary);
  secondary: var(--text-color-secondary);
  black: var(--text-color-black);
  white: var(--text-color-white);
  currentColor: var(--text-color-current);
};

each(@colors, {
	.color-@{key} {
		color: @value;
	}
})

@sizes: 14px, 16px;

each(@sizes, {
	.size-@{value} {
		font-size: @value;
	}
})

@weight: 400, 500;

each(@weight, {
	.weight-@{value} {
		font-weight: @value;
	}
})

.max-lines {
  display: -webkit-box;
  white-space: normal;
  -webkit-box-orient: vertical;

  &.max-lines-2 {
    -webkit-line-clamp: 2;
    line-clamp: 2;
  }
}
