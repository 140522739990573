.alertHolder {
	position: absolute;
	top: 0;
	right: 10px;
	z-index: 2;
}

.alert {
	display: flex;
	flex-direction: column;
	padding: 24px;
	border-radius: 12px;
	background: #ffffff;
	-webkit-box-shadow: 0 24px 38px rgba(0, 0, 0, 0.14),
		0 9px 46px rgba(0, 0, 0, 0.12), 0 11px 15px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 24px 38px rgba(0, 0, 0, 0.14),
		0 9px 46px rgba(0, 0, 0, 0.12), 0 11px 15px rgba(0, 0, 0, 0.2);
	box-shadow: 0 24px 38px rgba(0, 0, 0, 0.14), 0 9px 46px rgba(0, 0, 0, 0.12),
		0 11px 15px rgba(0, 0, 0, 0.2);
	animation: appearance 0.3s linear;
}

@keyframes appearance {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.actions {
	display: flex;
	justify-content: flex-end;
}

.text {
	margin-bottom: 20px;

	h4 {
		margin-bottom: 5px;
	}

	span {
		font-size: 15px;
	}
}
