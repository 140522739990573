.layout {
	background-color: #eee;
}

.container {
	box-sizing: border-box;
	display: grid;
	grid-template-rows: auto 1fr;
	row-gap: 40px;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-top: 17px;
	padding-bottom: 17px;
	max-width: 800px;

	&.mobile {
		padding-left: 8px;
		padding-right: 8px;
		max-width: none;
	}
}

.assignmentInfo {
	display: flex;
	justify-content: flex-end;
}

.mainInfo {
	display: flex;
}

.mainInfoText {
	&:not(:last-child) {
		margin-right: 16px;
	}
}

.assignmentList {
	display: flex;
	flex-direction: column;
	overflow: auto;

	.schoolYearAssignmentsBlock{
		&:not(:last-child){
			padding-bottom: 20px;
		}

		.schoolYearName{
			padding-bottom: 10px;
			color: rgb(0, 136, 204);
			font-weight: bold;
			font-size: 24px;
		}
	}
}

.assignmentEmpty {
	text-align: center;
	background: var(--page-title-bar-background-color-between-borders);
	border: var(--page-title-bar-outer-border);
	border-radius: 8px;
	padding: 12px 16px;
	font-size: 20px;
	font-weight: 600;
	letter-spacing: 1px;
}
