@font-path: "https://esgiwebfiles.s3.amazonaws.com/Fonts";

@font-face {
    src: url("@{font-path}/OpenSans-Regular.ttf") format('truetype'), url('@{font-path}/OpenSans-Regular.woff') format('woff'), url('@{font-path}/OpenSans-Regular.eot') format('eot');
}

@font-face {
    font-family: 'Dejavu Sans';
    src: url('@{font-path}/DejaVuSans.ttf') format('truetype'), url('@{font-path}/DejaVuSans.woff') format('woff'), url('@{font-path}/DejaVuSans.eot') format('eot');
}

@font-face {
    font-family: 'jokerman';
    src: url('@{font-path}/JOKERMAN.eot'), url('@{font-path}/JOKERMAN.woff') format('woff'), url('@{font-path}/JOKERMAN.ttf') format('truetype');
}

@font-face {
    font-family: 'Glyphicons Halflings';
    src: url('@{font-path}/glyphicons-halflings-regular.eot');
    src: url('@{font-path}/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'),url('@{font-path}/glyphicons-halflings-regular.woff') format('woff'),url('@{font-path}/glyphicons-halflings-regular.ttf') format('truetype'),url('@{font-path}/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}

@font-face {
    font-family: 'GochiHand';
    src: url('@{font-path}/GochiHand-Regular.eot');
    font-weight: normal;
    font-style: normal;
    font-family: 'GochiHand';
    src: url('@{font-path}/GochiHand-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: VideoJS;
    src: url('@{font-path}/vjs.eot');
    src: url('@{font-path}/vjs.eot?#iefix') format('embedded-opentype'),url('@{font-path}/vjs.woff') format('woff'),url('@{font-path}/vjs.ttf') format('truetype'),url('@{font-path}/vjs.svg#icomoon') format('svg');
    font-weight: 400;
    font-style: normal;
}



@font-face {
    font-family:"AbcMath";
    src: url('@{font-path}/abcmath.eot');
    src: url('@{font-path}/abcmath.eot?') format('embedded-opentype'),
         url('@{font-path}/abcmath.woff') format('woff'),
         url('@{font-path}/abcmath.ttf') format('truetype'),
         url('@{font-path}/abcmath.svg#AbcMathRegular') format('svg');
}


@font-face {
    font-family: "AbcDnManuscript";
    src: url('@{font-path}/abcdnmanuscript.eot');
    src: url('@{font-path}/abcdnmanuscript.eot?') format('embedded-opentype'),
         url('@{font-path}/abcdnmanuscript.woff') format('woff'),
         url('@{font-path}/abcdnmanuscript.ttf') format('truetype'),
         url('@{font-path}/abcdnmanuscript.svg#AbcDnManuscriptRegular') format('svg');
}

@font-face {
    font-family: "AbcCursive";
    src: url('@{font-path}/abccursive.eot');
    src: url('@{font-path}/abccursive.eot?') format('embedded-opentype'),
         url('@{font-path}/abccursive.woff') format('woff'),
         url('@{font-path}/abccursive.ttf') format('truetype'),
         url('@{font-path}/abccursive.svg#AbcCursiveRegular') format('svg');
}

@font-face {
    font-family: 'AbcBox01Print';
    src: url('@{font-path}/abcbox01print.eot');
    src: url('@{font-path}/abcbox01print.eot?') format('embedded-opentype'),
         url('@{font-path}/abcbox01print.woff') format('woff'),
         url('@{font-path}/abcbox01print.ttf') format('truetype'),
         url('@{font-path}/abcbox01print.svg#AbcBox01PrintRegular') format('svg');
}

@font-face {
    font-family: 'AbcPrint';
    src: url('@{font-path}/AbcPrint.otf')
}

@font-face {
    font-family: 'KGMissKindergarten';
    src: url('@{font-path}/KGMissKindergarten.ttf') format('truetype')
}

@font-face {
    font-family:"ShadowsIntoLight";
    src: url('@{font-path}/ShadowsIntoLight.ttf') format('truetype');
}

@font-face {
    font-family: Bangers; 
    src: url('@{font-path}/bangers.ttf');
}

@font-face {
    font-family: 'KG Happy';
    src: url('@{font-path}/KGHAPPYSolid.ttf');
}

@font-face {
    font-family: 'Oleo Script';
    src: url('@{font-path}/OleoScript-Regular.ttf') format('truetype');
}
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap');

@font-face {
  font-family: 'AbcPrintDottedLined';
  src: url('@{font-path}/abcprintdottedlined.otf')
}

@font-face {
  font-family: 'AbcPrintLined';
  src: url('@{font-path}/AbcPrintLinedRegular.otf')
}
