.card {
	display: flex;
	flex-direction: column;
	text-align: center;
	margin: 0px 4px;

	&.disabled {
		opacity: 0.6;
		pointer-events: none;
	}

	.content {
		position: absolute;
		width: 347px;
		height: 463px;
	}
}

@media only screen and (min-width: 1024px) and (max-width: 1137px) {
	.card {
		> svg,
		> img,
		.content {
			width: 307px;
			height: 419px;
		}
	}
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
	.card {
		margin: 0px 2px;

		> svg,
		> img,
		.content {
			width: 230px;
			height: 314px;
		}
	}
}
