.template {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 50% 50%;
	width: 100%;
	height: 100%;
	justify-content: center;
	@media (max-height: 690px) {
		grid-template-rows: 42% 58%;
	}
}

.optionsContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
}

.cardsContainer {
	display: grid;
	height: 100%;
	width: 100%;
	grid-template-rows: 100%;
	grid-template-columns: 1fr 1fr;
	gap: 20px;
}

.card {
	height: 100%;
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
}

.cardImg {
	padding: 15px;
	flex-grow: 1;
	display: flex;
	max-width: 30%;
	overflow: auto;
	align-items: center;
	justify-content: center;
	height: auto;

	img {
		max-height: 100%;
		max-width: 100%;
		width: auto;
		height: auto;
	}
}

.cardText {
	font-style: normal;
	font-weight: 400;
	font-size: 32px;
	line-height: 54px;
	color: #FFF;
}

.cardImg ~ .cardText {
	margin-left: 20px;
}

.optionBox {
	max-height: 100%;
	height: 100%;
}

.optionBox ~ .optionBox {
	margin-left: 20px;
}

@media (max-height: 830px) {
	.customizeOption {
		min-height: initial !important;
	}
}

@media (max-height: 780px) {
	.customizeAction {
		padding: 5px !important;

		> svg {
			width: 40px;
			height: 40px;
		}
	}
}