.template {
	align-items: center;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	justify-content: center;
	@media (max-height: 600px) {
		padding: 0;
	}
}

.optionsContainer {
	max-height: 370px;
	display: flex;
	justify-content: center;
	width: 100%;
}

.questionContainer {
	flex-direction: column;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	justify-items: center;
	margin-bottom: 64px;

	.imgContainer {
		padding: 15px;
		display: flex;
		overflow: auto;
		align-items: center;
		justify-content: center;
		height: 100%;
		img {
			max-height: 100%;
			max-width: 100%;
			width: auto;
			height: auto;
		}
		@media (max-height: 700px) {
			padding: 0;
		}
		@media (max-height: 600px) {
			padding: 0;
			max-height: 150px;
		}
	}

	> span {
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
		text-align: left;
        line-height: 54px;
        margin: 0 20px;
        color: #FFF;
    }
}

.optionBox {
	justify-content: center;
	flex-direction: column;
	display: flex;
	width: 100%;
	flex: 1;
	max-height: 370px;

	@media (max-height: 600px) {
		max-height: max-content;
	}
}

.optionBox ~ .optionBox {
	margin-left: 20px;
}

.text {
	display: flex;
	align-items: center;
	padding: 15px;
	color: #fff;
	font-size: 32px;
	font-style: normal;
	font-weight: 400;
	line-height: 36px;
	text-align: left;
}

.textContainer {
	min-height: 90px;
	display: flex;
	height: 100%;
	align-items: center;
}


@media (max-height: 830px) {
	.customizeOption {
		min-height: initial !important;
	}
}

@media (max-height: 780px) {
	.customizeAction {
		padding: 2px !important;

		> svg {
			width: 25px;
			height: 25px;
		}
	}

	.questionContainer {
		flex: 1;
		height: auto;
		max-height: 150px;
		margin-bottom: 10px;

		.imgContainer {
			padding: 0 !important;
		}

		.text {
			font-size: 28px;
			padding: 5px;
		}

		.textContainer {
			min-height: auto;
			height: auto;

			button {
				min-height: auto;
				height: 100%;
				display: flex;
				align-items: center;

				svg {
					width: 45px;
					height: 45px;
				}
			}
		}
	}

	.optionsContainer {
		min-height: 55%;
		overflow: hidden;
		margin-bottom: 0;

		button {
			svg {
				width: 45px;
				height: 45px;
			}
		}

		.customizeOption {
			min-height: 100px !important;
		}

		.customizeAudiBtn {
			display: flex;
			justify-content: center;
			min-height: auto;
		}

	}
}

@media (max-height: 610px) {
	.customizeAction {

		> svg {
			width: 30px;
			height: 30px;
		}
	}
}