/* LESS - http://lesscss.org style sheet */
/* Palette color codes */
/* Palette URL: http://paletton.com/#uid=73x0X0ktAWMf-X-mCWDIkVfRNAz */

/* http://paletton.com/#uid=73f0u0kw0ujlhAGqdx3yqonD0jC - New Pallete (8/27/15 ) */
/* Feel free to copy&paste color codes to your application */

/* MIXINS */

/* As hex codes */
@bg-color:#fff;
@palette-color: #fff;//@bg-color;

@primary: @palette-color; /* Main Primary color */
@primary-lighter: desaturate(lighten(@palette-color, 22%), 58%);
@primary-light: desaturate(lighten(@palette-color, 50%), 59%);
@primary-darker: darken(@palette-color, 6%);
@primary-dark: darken(@palette-color, 15%);

@palette-color: spin(@bg-color, 30);

@secondary: @palette-color; /* Main Secondary color 1 */
@secondary-lighter: desaturate(lighten(@palette-color, 22%), 58%);
@secondary-light: desaturate(lighten(@palette-color, 50%), 59%);
@secondary-darker: darken(@palette-color, 6%);
@secondary-dark: darken(@palette-color, 15%);

@palette-color: spin(@bg-color, 180);

@tetrary: @palette-color;
@tetrary-lighter: desaturate(lighten(@palette-color, 22%), 58%);
@tetrary-light: desaturate(lighten(@palette-color, 50%), 59%);
@tetrary-darker: darken(@palette-color, 6%);
@tetrary-dark: darken(@palette-color, 15%);

@palette-color: spin(@bg-color, 210);

@complement: @palette-color;
@complement-lighter: desaturate(lighten(@palette-color, 22%), 58%);
@complement-light: desaturate(lighten(@palette-color, 50%), 59%);
@complement-darker: darken(@palette-color, 6%);
@complement-dark: darken(@palette-color, 15%);

body {
	font-family: Roboto, "Open Sans", sans-serif;
}

.bonnie {
	.form-group {
		text-align: left;
		min-height: 38px;
		display: flex;
		align-items: center;
		margin: 0 0 15px 0;

		label {
			padding-top: 0;
			margin-right: 8px;
		}
	}

	.form-control {
		height: 38px;
		font-size: 14px;
		color: #424242;
		border: #bdbdbd 1px solid;
		padding: 7px;
	}

}

td > label, th > label {
	margin-bottom: 0;
	line-height: inherit;
	vertical-align: middle;
}

.table-bonnie {
	// important is used because of bootstrap overspecified styles
	border-collapse: separate;

	th {
		border: 1px solid #bdbdbd !important;
		border-left: none !important;
		border-top: 1px solid #bdbdbd !important;
		font-weight: normal;
		text-align: center;
		background-color: #e0e0e0;
		padding: 14px 8px !important;
	}

	td {
		border-right: 1px solid #ddd !important;
		border-bottom: 1px solid #ddd !important;
		border-top: 0 !important;
		border-left: 0 !important;
		padding: 14px 8px !important;
	}

	th:last-child, td:last-child
	{
		border-right: none !important;
	}

	tr:last-child {
		td {
			border-bottom: none !important;
		}
	}
}
.tooltip.fade .tooltip-inner{
	text-align: start;
}
a, .btn-link {
	color: #0077B8;

	&:hover {
		color: #0077B8;
	}
}

.state-standard {
    .nav-tabs > li.active > a {
        border-top-color: @primary-darker;
    }
}

body {
	--theme-radio-color: #32A3CE;
	--theme-checkbox-color: #32A3CE;
}

input[ type = 'checkbox' ].ace,
input[ type = 'radio' ].ace {
	opacity: 0;
	position: absolute;
	z-index: 1;
	width: 18px;
	height: 18px;
	cursor: pointer;
}

label input[ type = 'checkbox' ].ace,
label input[ type = 'radio' ].ace {
	z-index: -100 !important;
	width: 0 !important;
	height: 0 !important;
	position: fixed;
}

input[ type = 'checkbox' ].ace:checked,
input[ type = 'radio' ].ace:checked,
input[ type = 'checkbox' ].ace:focus,
input[ type = 'radio' ].ace:focus {
	outline: none !important;
}

.lbl {
	font-size: 14px;
}

input[ type = 'checkbox' ].ace + .lbl,
input[ type = 'radio' ].ace + .lbl {
	display: inline-block;
	margin: 0;
	line-height: 20px;
	min-height: 18px;
	min-width: 18px;
	font-weight: normal;
	cursor: pointer;
}

input[ type = 'checkbox' ].ace + .lbl::before,
input[ type = 'radio' ].ace + .lbl::before {
	cursor: pointer;
	font-family: fontAwesome;
	font-weight: normal;
	font-size: 12px;
	color: #32A3CE;
	content: '\a0';
	background-color: #FAFAFA;
	border: 1px solid #C8C8C8;
	box-shadow: 0 1px 2px rgba(0, 0, 0, .05);
	border-radius: 0;
	display: inline-block;
	text-align: center;
	height: 16px;
	line-height: 14px;
	min-width: 16px;
	margin-right: 5px;
	top: -1px;
}

input[ type = 'radio' ].ace + .lbl::before {
	border-radius: 100%;
	font-size: 10px;
	font-family: FontAwesome;
	text-shadow: 0 0 1px #32A3CE;
	line-height: 15px;
	height: 17px;
	min-width: 17px;
}

input[ type = 'checkbox' ].ace:checked + .lbl::before,
input[ type = 'radio' ].ace:checked + .lbl::before {
	display: inline-block;
	content: '\f00c';
	background-color: #F5F8FC;
	border-color: #ADB8C0;
	box-shadow: 0 1px 2px rgba(0, 0, 0, .05), inset 0px -15px 10px -12px rgba(0, 0, 0, .05), inset 15px 10px -12px rgba(255, 255, 255, .1);
}

input[ type = 'checkbox' ].ace:active + .lbl::before,
input[ type = 'radio' ].ace:active + .lbl::before,
input[ type = 'checkbox' ].ace:checked:active + .lbl::before,
input[ type = 'radio' ].ace:checked:active + .lbl::before {
	box-shadow: 0 1px 2px rgba(0, 0, 0, .05), inset 0px 1px 3px rgba(0, 0, 0, .1);
}

input[ type = 'checkbox' ].ace.ace-checkbox-2 + .lbl::before,
input[ type = 'radio' ].ace.ace-checkbox-2 + .lbl::before {
	box-shadow: none;
}

input[ type = 'checkbox' ].ace.ace-checkbox-2:checked + .lbl::before,
input[ type = 'radio' ].ace.ace-checkbox-2:checked + .lbl::before {
	background-color: #F9A021;
	border-color: #F9A021;
	color: #FFF;
}

input[ type = 'checkbox' ].ace:disabled + .lbl::before,
input[ type = 'radio' ].ace:disabled + .lbl::before,
input[ type = 'checkbox' ].ace[ disabled ] + .lbl::before,
input[ type = 'radio' ].ace[ disabled ] + .lbl::before,
input[ type = 'checkbox' ].ace.disabled + .lbl::before,
input[ type = 'radio' ].ace.disabled + .lbl::before {
	background-color: #DDD !important;
	border-color: #CCC !important;
	box-shadow: none !important;
	color: #BBB;
}

button {
	i.fa + span {
		margin-left: 5px;
	}

	span + i.fa {
		margin-left: 5px;
	}
}


[ type = 'radio' ].theme {
	&:checked,
	&:not(:checked) {
		position: absolute;
		left: -9999px;
	}

	&:checked + label,
	&:not(:checked) + label {
		position: relative;
		padding-left: 22px;
		cursor: pointer;
		line-height: 20px;
		display: inline-block;
	}

	&:checked + label {
		font-weight: bold;
	}

	&:not(:checked) + label {
		font-weight: normal;
	}

	&:checked + label:before,
	&:not(:checked) + label:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 18px;
		height: 18px;
		border-radius: 100%;
	}

	&:not(:checked) + label:before {
		border: 2px solid #828282;
	}

	&:checked + label:before {
		border: 2px solid var(--theme-radio-color);
		background: white;
	}

	&:checked + label:after,
	&:not(:checked) + label:after {
		content: '';
		width: 8px;
		height: 8px;
		background: var(--theme-radio-color);
		position: absolute;
		top: 5px;
		left: 5px;
		border-radius: 100%;
		-webkit-transition: all .2s ease;
		transition: all .2s ease;
	}

	&:not(:checked) + label:after {
		opacity: 0;
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	&:checked + label:after {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

[ type = 'checkbox' ].theme {

	position: absolute; // take it out of document flow
	opacity: 0; // hide it

	& + label {
		position: relative;
		cursor: pointer;
		padding-left: 22px;
	}

	&:checked + label > span {
		font-weight: bold;
	}

	&:not(:checked) + label > span {
		font-weight: normal;
	}
	// Box.
	& + label:before {
		content: '';
		margin-right: 10px;
		width: 18px;
		height: 18px;
		position: absolute;
		top: 50%;
		left: 0;
		transform: translateY(-50%);
	}
	// Box hover
	&:hover + label:before {
		background: var(--theme-checkbox-color);
	}
	// Box focus
	&:focus + label:before {
		//box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
	}
	// Box checked
	&:checked + label:before {
		background: var(--theme-checkbox-color);
	}

	&:not(:checked) + label:before {
		border: 2px solid #828282;
		background: none;
	}
	// Disabled state label.
	&:disabled + label {
		color: #B8B8B8;
		cursor: auto;
	}
	// Disabled box.
	&:disabled + label:before {
		box-shadow: none;
		background: #DDD;
	}
	// Checkmark. Could be replaced with an image
	&:checked + label:after {
		content: '';
		position: absolute;
		left: 4px;
		top: 50%;
		background: white;
		width: 2px;
		height: 2px;
		box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
		transform: rotate(45deg) translateY(-50%) translateX(-50%);
	}
}

.color-picker {
	display: flex;

	input {
		flex: 1;
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
		border-right: none !important;
		min-width: 0;
	}

	input:focus + .color-picker-container {
		.dropdown-colorpicker {
			border-color: #66AFE9;
		}
	}

	.color-picker-container {
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	}

	.dropdown-colorpicker {
		width: 34px;
		height: 100%;
		border: 1px solid #BBB;
		transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;

		a {
			width: 100%;
			height: 100%;
			display: block;
		}

		.dropdown-menu {
			width: 202px;
			min-width: auto;
			max-width: none;
		}

		.dropdown-toggle {
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
		}
	}
}
