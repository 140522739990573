.content {
	position: relative;
}

.container {
	background-color: #00a8ff;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.buttonLogin {
	position: absolute;
	bottom: 2.2%;
	right: 3.3%;
	width: 75px;
	height: 75px;

	svg {
		position: absolute;
		margin: auto;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
}

.formContainer {
	width: 376px;
	box-sizing: border-box;
	background-color: #fff;
	padding: 32px 24px 32px;
	border: 1px solid #bdbdbd;
	box-shadow: 0px 24px 38px rgba(0, 0, 0, 0.14),
		0px 9px 46px rgba(0, 0, 0, 0.12), 0px 11px 15px rgba(0, 0, 0, 0.2);
	border-radius: 12px;
	z-index: 9;
}

.form {
	display: flex;
	flex-direction: column;

	.formElement {
		margin-bottom: 0;
		text-align: center;
		display: flex;
		flex-direction: column;

		&:not(:last-child) {
			margin-bottom: 30px;
		}
	}
}

.formElement {
	input {
		border: 2px solid #81d4f7;
		border-radius: 6px;
		height: 52px;
		font-size: 16px;

		&.success {
			border: 2px solid #81d4f7;
		}

		&.error {
			box-shadow: none;

			&:active, &:focus, &:hover {
				box-shadow: none !important;
			}
		}

		&:hover {
			border-width: 2px;
		}

		&:active, &:focus, &:hover {
			box-shadow: none;
		}
	}
}

.label {
	font-weight: 500;
	font-size: 16px;
	letter-spacing: 0.5px;
	color: #666666;
	margin-bottom: 10px;
}

.puzzleWhite {
	left: 30%;
	top: 66%;
}

.puzzleYellow {
	left: 65%;
	top: 45%;
}

.puzzleGreen {
	left: 2%;
	top: 29.5%;
}

.puzzleOrrange {
	left: 17%;
	top: -9%;
}
