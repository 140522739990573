@errorColor: #ff0c3e;

.errorMessage {
	font-size: 12px;
	font-weight: 400;
	line-height: 14px;
  color: @errorColor;
}

@align: left, center, right;

each(@align, {
  .align-@{value} {
    text-align: @value;
  }
});
