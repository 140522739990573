:root {
	/* Page Title Bar Container */
	--page-title-bar-outer-border: 3px solid #51c3f2;
	--page-title-bar-inner-border: 1px solid #81d4f7;
	--page-title-bar-background-color-between-borders: #e1f5fd;

	/* Page Title Bar Circles */
	--page-title-bar-circle-border-radius: 50%;
	--page-title-bar-circle-outer-size: 94px;
	--page-title-bar-circle-inner-size: 75px;

	/* Page Title */
	--page-title-bar-border-radius: 36px;
}

.nameBarContainer {
	position: relative;

	&::before,
	&::after {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 1;
		display: block;
		margin: auto;
		content: "";
		border-radius: var(--page-title-bar-circle-border-radius);
	}

	&::before {
		width: var(--page-title-bar-circle-outer-size);
		height: var(--page-title-bar-circle-outer-size);
		background: var(--page-title-bar-background-color-between-borders);
		border: var(--page-title-bar-outer-border);
	}

	&::after {
		width: var(--page-title-bar-circle-inner-size);
		height: var(--page-title-bar-circle-inner-size);
		background: var(--page-header-background-color);
		border: var(--page-title-bar-inner-border);
	}
}

.title {
	position: relative;
	z-index: 2;
	padding: 5px;
	background: var(--page-title-bar-background-color-between-borders);
	border: var(--page-title-bar-outer-border);
	border-radius: var(--page-title-bar-border-radius);

	div {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 5px 15px;
		background: var(--page-header-background-color);
		border: var(--page-title-bar-inner-border);
		border-radius: var(--page-title-bar-border-radius);

		h2 {
			margin: 0;
			font-family: "Baloo Da 2";
			font-size: 20px;
			font-weight: 600;
			color: #0077b8;
			letter-spacing: 2px;
			margin-left: 10px;
			margin-right: 10px;
		}
	}
}
