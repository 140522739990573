.template {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.optionsContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;

	@media (max-height: 600px) {
		padding: 15px;
	}
}

.optionBox {
	width: 600px;
	flex: initial;
	flex-direction: row;
	min-height: 110px;

	&:not(:last-child) {
		margin-bottom: 20px;
	}

	@media (max-height: 600px) {
		&:not(:last-child) {
			margin-bottom: 10px;
		}
		min-height: auto;
	}
}

.fourOptions {
	@media (max-height: 800px) {
		min-height: unset;
		max-height: 84px;
	}
}

@media (max-height: 800px) {
	.threeOptions {
		min-height: unset;
		max-height: 130px;
		height: 120px;

		& span {
			padding-top: 10px;
			padding-bottom: 10px;
			padding-right: 10px;
		}
	}
}


