.baseModal {
	width: 390px;
	font-size: 24px;
	line-height: 36px;
}

.button {
	font-weight: 500;
	letter-spacing: 1.25px;
}
