.start {
	position: absolute;
	top: 92%;
	transition: 0.3s;
	width: 75px;
	height: 75px;

	svg {
		position: absolute;
    top: 0;
    bottom: 0;
    left: 38%;
    margin: auto;
	}

	&:active {
		transform: scale(1.25);
	}
}

@media (max-width: 1024px) {
	.start {
		top: 88%;
	}
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
	.start {
		top: 85%;
		width: 70px;
		height: 70px;
	}
}
