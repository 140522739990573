.modalWrapper {
	height: 100%;
	padding: 0;
	border-radius: 0;
}

.modal {
	width: 100%;
	height: 100%;
	border-radius: 0;
	border: none;
	background-color: rgba(18, 18, 18, 1);
}

.testHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	height: 135px;
}

.questionContainer {
	display: flex;
	align-items: center;
	justify-content: center;

	button {
		padding-left: 30px;
	}

	span {
		font-style: normal;
		font-weight: 500;
		font-size: 28px;
		line-height: 30px;
		color: #fff;
		padding-left: 20px;
		text-align: left;
	}
}

.testBody {
	margin: auto;
	width: 100%;
	flex-grow: 1;
	padding-top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.questionWrapper {
	width: 1133px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.testFooter {
	display: flex;
	justify-content: space-between;
	padding: 20px 50px;
}

.testBody,
.testHeader,
.testFooter {
	background: transparent;
	border-radius: 0;
}
