:root {
	/* Page Header */
	--page-header-padding: 28px;
	--page-header-background-color: #fff;
	--page-header-min-height: 150px;
	--page-header-z-index: 9;
	--page-header-logout-color: #666;
	--page-header-logout-hover-bg-color: #51c3f2;
	--page-header-logout-hover-color: #fff;
}

.page {
	display: grid;
	grid-template-rows: auto 1fr;
	height: 100%;

	header {
		box-sizing: border-box;
		display: grid;
		min-height: var(--page-header-min-height);
		padding: var(--page-header-padding);
		background: var(--page-header-background-color);
		z-index: var(--page-header-z-index);

		.namebar {
			justify-self: center;
			align-self: center;
		}

		&.withLogoutButton {
			.namebar {
				grid-column: 1 / -1;
				grid-row: 1;
			}
		}
	}

	main {
		height: 100%;
		overflow: hidden;
	}
}

.logout {
	justify-self: right;
	grid-column: 1 / -1;
	grid-row: 1;
	color: var(--page-header-logout-color);
	text-transform: capitalize;
	font-size: 16px;
	font-weight: 400;

	svg {
		fill: var(--page-header-logout-color);
	}

	&:hover {
		background-color: var(--page-header-logout-hover-bg-color);
		font-weight: 500;
		color: var(--page-header-logout-hover-color);

		svg {
			fill: var(--page-header-logout-hover-color);
		}
	}
}
