.questionWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
}

.exampleBtn {
	border: none;
	background: none;
	margin: 85px;
	transition: 0.3s;
	>svg {
		overflow: visible;
	}
}

.exampleBtn:disabled,
.exampleBtn[disabled] {
	pointer-events: none;
}

.exampleBtn:hover {
	transform: scale(1.5);
}

.active {
	transform: scale(1.5);
	svg {
		circle {
			stroke-width: 6px;
		}
	}
}

.audio {
	&.active {
		svg {
			circle {
				stroke-width: 3px;
				stroke: #FFF;
				stroke-opacity: 1;
			}
		}
	}
}

.back {
	&.active, &:hover {
		svg {
			circle {
				stroke: #51C3F2;
			}

			path {
				fill: #51C3F2;
			}
		}
	}

	// transform: scale(1.2);
}