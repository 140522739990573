.modalWrapper {
	height: 100%;
	padding: 0;
	border-radius: 0;
}

.modal {
	width: 100%;
	height: 100%;
	border-radius: 0;
	border: none;
}

.launcherHeader {
	padding: 28px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.starredNameContainer {
	margin-left: auto;
	margin-right: auto;
}

.body {
	padding-top: 0;
	background-color: #eeeeee;
	flex-grow: 1;
	overflow: auto;
}

.launcherBody {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cardsContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-bottom: 10px;
}
