.actionBtn {
    border: none;
    background: none;
    transition: 0.3s;
    
    >svg {
        overflow: visible;
    }
}

.actionBtn:disabled,
.actionBtn[disabled] {
    pointer-events: none;
}

.nextBtn:hover, .actionBtn:active {
    transform: scale(1.2);
}

.actionBtn:active {
    svg {
        circle {
            stroke-width: 6px;
        }
    }
}

.backBtn:hover, .backBtn:active {
    svg {
        circle {
            stroke:#51C3F2;
        }

        path {
            fill: #51C3F2;
        }
    }
}