.cardHeader {
	font-weight: 600;
	font-size: 34px;
	line-height: 57px;
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
	.cardHeader {
		font-size: 22px;
		line-height: 42px;
	}
}
