.modalWrapper {
	height: 100%;
	padding: 0;
	border-radius: 0;
}

.modal {
	width: 100%;
	height: 100%;
	border-radius: 0;
	border: none;
}

.modalHeader {
	padding: 28px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.starredNameContainer {
	margin-left: auto;
	margin-right: auto;
}

.modalBody {
	height: 594px;
	background: #00a8ff;
	font-family: "Roboto";
	font-style: normal;
	font-weight: 500;
	font-size: 32px;
	line-height: 36px;
	color: #fff;
}

.intro {
	display: flex;
	align-items: center;
	justify-content: center;
	justify-items: center;
	width: 100%;

	> span {
		text-align: left;
		width: 186px;
	}
}

@media (max-width: 1023px) {
	.intro {
		flex-direction: column;
		> span {
			text-align: center;
			width: 100%;
		}
	}
}

.snakeContainer {
	display: flex;
	flex-grow: 1;
	align-items: center;
	justify-content: center;
	overflow: auto;

	> img {
		width: auto;
		height: auto;
		max-width: 100%;
		max-height: 100%;
	}
}
