:root {
	/* Button Circled */
	--button-circled-border-size: 3px;
	--button-circled-border-color: #fff;
	--button-circled-border-radius: 50%;
	--button-circled-disabled-opacity: 0.6;

	/* Button Circled: Color Success */
	--button-circled-success-background: #2fb655;
	--button-circled-success-background-hover: #2caa50; /* hsl, L - 3% */
}

.button {
	touch-action: manipulation;
	cursor: pointer;
	user-select: none;
	background-color: var(--button-circled-background);
	border-color: var(--button-circled-border-color);
	border-style: solid;
	border-width: var(--button-circled-border-size);
	border-radius: var(--button-circled-border-radius);
	outline: none;
	box-shadow: 0 2px 4px rgb(0 0 0 / 14%), 0 3px 4px rgb(0 0 0 / 12%),
		0 1px 5px rgb(0 0 0 / 20%);
	transition: background-color 0.1s;
	appearance: none;

	&:hover:not(.disabled) {
		background-color: var(--button-circled-background-hover);
	}
}

.disabled {
	opacity: var(--button-circled-disabled-opacity);
	cursor: not-allowed;
}

.color-success {
	--button-circled-background: var(--button-circled-success-background);
	--button-circled-background-hover: var(
		--button-circled-success-background-hover
	);
}
