.buttonsModal {
	width: 390px;
	padding-top: 0;

	span {
		font-size: 16px;
		letter-spacing: 0.15px;
		line-height: 24px;
		text-align: left;
		color: #666666;
	}
}

.button {
	font-weight: 500;
	letter-spacing: 1.25px;
}
